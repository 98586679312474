import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import {
  showModalAbonosAction,
  showModalAbonarAction,
  eliminarAbonoAction,
  imprimirAbonoAction,
  agregarAbonoAction,
} from "../../actions/abonosActions";
import ReactDatatable from "@mkikets/react-datatable";
import ModalAgregarAbono from "./ModalAgregarAbono";
import { ConfirmModal } from "../../components/ConfirmModal";
import ModalMetodoPagoAbono from "./ModalMetodoPagoAbono";

export default (props) => {
  const dispatch = useDispatch();
  const { cliente_tratamiento_abono, abonos, showModalAbonos } = useSelector(
    (state) => state.abonos
  );

  const [idCliente, setIdCliente] = useState(null);

  useEffect(() => {
    setIdCliente(props.idCliente);
  }, [props.idCliente]);

  const reimprimir = (id) => {
    dispatch(imprimirAbonoAction(id));
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "monto",
      text: "Monto",
      align: "left",
      sortable: true,
    },
    {
      key: "fecha",
      text: "Fecha",
      sortable: true,
    },
    {
      key: "metodo_pago.metodo",
      text: "Metodo de pago",
      sortable: true,
      cell: (record) => {
        return record.metodo_pago.metodo;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Reimprimir ticket</Tooltip>}
              >
                <button
                  onClick={() => reimprimir(record.id)}
                  className="btn btn-success btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-file-invoice"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <button
                onClick={() => eliminarAbono(record)}
                className="btn btn-danger btn-sm"
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          </>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-success",
      title: "Agregar abono",
      children: [
        <span>
          <i class="fas fa-plus"></i> Agregar Abono
        </span>,
      ],
      onClick: (event) => {
        dispatch(showModalAbonarAction(true));
      },
    },
  ];

  const configDataTable = {
    button: {
      extra: true,
    },
  };

  const handleClose = () => {
    dispatch(showModalAbonosAction(false));
  };

  const submitForm = (metodosPago) => {
    const params = {
      metodos_pago: metodosPago,
      tabla: props.tabla,
      id_tabla: cliente_tratamiento_abono?.id,
      id_cliente: idCliente
    };

    dispatch(agregarAbonoAction(params));
  };

  const eliminarAbono = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarAbonoAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModalAbonos}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Abonos</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ReactDatatable
            hover
            records={abonos}
            columns={columns}
            extraButtons={extraButtons}
            config={configDataTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {idCliente && (
        <ModalMetodoPagoAbono
          id="modal-metodo-pago-abono"
          onSubmitForm={(metodosPago) => submitForm(metodosPago)}
          idCliente={idCliente}
        />
      )}
    </>
  );
};
