import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCashRegister,
  faDollarSign,
  faMoneyCheck,
  faSortAmountUp,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Card,
  Table,
  Form,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  CounterWidget,
  CircleChartWidget,
  BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  SalesValueWidget,
  SalesValueWidgetPhone,
  AcquisitionWidget,
} from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import { getEstadisticasAction } from "../../actions/estadisticasActions";
import { formatAMPM, numberFormat } from "../../helpers";
import {
  ClientesRegistradosChart,
  ClientesRegistradosChartPhone,
  SalesValueChart,
} from "../../components/Charts";

import { Chart } from "react-google-charts";
import LineEChart from "../../components/Estadisticas/LineEChart";
import PieEChart from "../../components/Estadisticas/PieEChart";
import BarEChart from "../../components/Estadisticas/BarEChart";
import clienteAxios from "../../config/axios";

export default () => {
  const dispatch = useDispatch();

  const { loadingEstadisticas, estadisticas: estadisticasOriginal } =
    useSelector((state) => state.estadisticas);

  const [dataComisiones, setDataComisiones] = useState([]);

  const [parametros, setParametros] = useState({});

  const [estadisticas, setEstadisticas] = useState({});

  useEffect(() => {
    dispatch(getEstadisticasAction());
  }, []);

  useEffect(() => {
    setEstadisticas(estadisticasOriginal);
  }, [estadisticasOriginal]);

  useEffect(() => {
    if (estadisticas.historial_comisiones_ultimos_7_dias) {
      setDataComisiones([
        ["Dia", "Total"],
        ...Object.entries(estadisticas.historial_comisiones_ultimos_7_dias).map(
          ([key, value]) => [key, value]
        ),
      ]);
    }
  }, [estadisticas.historial_comisiones_ultimos_7_dias]);

  const optionsComisiones = {
    chart: {
      title: "",
      subtitle: "",
    },
    legend: {
      position: "none",
    },
  };

  const refrescarDatos = () => {
    dispatch(getEstadisticasAction());
  };

  const onChangeParametros = (estadistica, e) => {
    setParametros({
      ...parametros,
      [estadistica]: {
        ...parametros[estadistica],
        [e.target.name]: e.target.value,
      },
    });
  };

  const getEstadistica = (estadistica) => {
    const request = clienteAxios
      .get("estadistica/" + estadistica, {
        params: parametros[estadistica] || null,
      })
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          console.log(estadisticas);
          console.log(respuesta.data.data);
          setEstadisticas({
            ...estadisticas,
            [estadistica.toLowerCase()]: respuesta.data.data,
          });
        }
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 text-right">
        <Col className="text-right">
          <Button
            variant="primary"
            size="sm"
            onClick={refrescarDatos}
            disabled={loadingEstadisticas}
          >
            <i class="fas fa-sync"></i> Refrescar datos
          </Button>
        </Col>
      </div>

      <Row className="justify-content-md-center">
        {"comision_diaria_comisionista" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Comisión de hoy"
              title={"$" + estadisticas.comision_diaria_comisionista}
              fontColor="#0acf97"
              iconColor="shape-tertiary"
            />
          </Col>
        )}

        {"comision_semanal_comisionista" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Comisión total en la semana"
              title={"$" + estadisticas.comision_semanal_comisionista}
              fontColor="#0acf97"
              iconColor="shape-tertiary"
            />
          </Col>
        )}

        {"comision_mensual_comisionista" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Comisión total en el mes"
              title={"$" + estadisticas.comision_mensual_comisionista}
              fontColor="#0acf97"
              iconColor="shape-tertiary"
            />
          </Col>
        )}

        {"servicios_realizados_comisionista" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Servicios realizados"
              title={estadisticas.servicios_realizados_comisionista}
              iconColor="shape-tertiary"
            />
          </Col>
        )}

        {"clientes_comisionista" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Clientes"
              title={estadisticas.clientes_comisionista}
              iconColor="shape-tertiary"
            />
          </Col>
        )}
      </Row>

      <Row className="justify-content-md-center">
        {"venta_diaria" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Venta de hoy"
              title={"$" + estadisticas.venta_diaria}
              fontColor="#0acf97"
              icon={faDollarSign}
              iconColor="shape-tertiary"
            />
          </Col>
        )}
        {"total_ventas" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Ventas realizadas"
              title={estadisticas.total_ventas}
              icon={faCashRegister}
              iconColor="shape-tertiary"
            />
          </Col>
        )}

        {"total_clientes" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Clientes"
              title={estadisticas.total_clientes}
              icon={faUsers}
              iconColor="shape-secondary"
            />
          </Col>
        )}

        {"total_tratamientos_realizados" in estadisticas && (
          <Col className="mb-4">
            <CounterWidget
              category="Tratamientos hechos"
              title={estadisticas.total_tratamientos_realizados}
              icon={faSortAmountUp}
              iconColor="shape-tertiary"
            />
          </Col>
        )}
      </Row>

      <Row className="justify-content-md-center">
        {"ingresos" in estadisticas && (
          <Col sm={12} className="mb-4">
            <LineEChart
              data={estadisticas.ingresos}
              title="Ingresos"
              serieLabel="Total"
              identificador="INGRESOS"
              color1="#01D331"
              color2="#67FF8A"
              lineColor="#67FF8A"
            />
          </Col>
        )}

        {"utilidades" in estadisticas && (
          <Col sm={12} className="mb-4">
            <LineEChart
              data={estadisticas.utilidades}
              title="Utilidades"
              serieLabel="Total"
              identificador="UTILIDADES"
              color1="#00FFA2"
              color2="#04CA82"
              lineColor="#00FF97"
            />
          </Col>
        )}

        {/**{"ingresos_gastos" in estadisticas && (
          <Col xs={12} sm={4} xl={4} className="mb-4">
            <PieEChart
              title="Ingresos y Gastos de hoy"
              data={estadisticas.ingresos_gastos}
            />
          </Col>
        )}**/}
      </Row>

      <Row>
        {"historial_ventas" in estadisticas && (
          <Col xs={12} md={6} className="mb-4">
            <LineEChart
              data={estadisticas.historial_ventas}
              title="Cantidad Ventas"
              serieLabel="Cantidad"
              identificador="HISTORIAL_VENTAS"
              color1="#084CFB"
              color2="#ADF3FD"
              lineColor="#ADF3FD"
            />
          </Col>
        )}

        <Col xs={12} md={6} className="mb-4">
          {"gastos_mensuales" in estadisticas && (
            <LineEChart
              data={estadisticas.gastos_mensuales}
              title="Gastos"
              serieLabel="Total"
              identificador="GASTOS_MENSUALES"
              color1="rgb(255, 158, 68)"
              color2="rgb(255, 70, 131)"
              lineColor="rgb(255, 70, 131)"
            />
          )}
        </Col>
        <Col xs={12} md={6} className="mb-4">
          {"nuevos_clientes_mensuales" in estadisticas && (
            <LineEChart
              data={estadisticas.nuevos_clientes_mensuales}
              title="Nuevos clientes"
              serieLabel="Cantidad"
              identificador="NUEVOS_CLIENTES_MENSUALES"
              color1="#FBA601"
              color2="#FDD18F"
              lineColor="#FBA601"
            />
          )}
        </Col>

        {"historial_comisiones" in estadisticas && (
          <Col xs={12} md={6} className="mb-4">
            <LineEChart
              data={estadisticas.historial_comisiones}
              title="Comisiones"
              serieLabel="Total"
              identificador="HISTORIAL_COMISIONES"
              color1="#C100FF"
              color2="#E07FFF"
              lineColor="#E07FFF"
            />
          </Col>
        )}
      </Row>

      <Row className="justify-content-md-center">
        {"top_productos_mas_vendidos" in estadisticas && (
          <>
            <Col xs={12} md={6} className="mb-4">
              <BarEChart
                title="Productos mas vendidos"
                data={estadisticas.top_productos_mas_vendidos}
                serieLabel="Cantidad"
              />
            </Col>
          </>
        )}

        {"totales_comisiones_detalladas" in estadisticas && (
          <>
            <Col xs={12} md={6} className="mb-4">
              <Card bg="default" className="shadow-sm">
                <Card.Header className="d-flex flex-row align-items-center flex-0">
                  <div className="d-block">
                    <h5 className="fw-normal mb-2">
                      Comisiones totales por comisionista
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-2">
                  {estadisticas.totales_comisiones_detalladas && (
                    <>
                      <Row className="mb-3">
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Fecha desde:</Form.Label>
                            <Form.Control
                              type="date"
                              value={parametros.fecha_desde}
                              name="fecha_desde"
                              size="sm"
                              onChange={(e) =>
                                onChangeParametros(
                                  "TOTALES_COMISIONES_DETALLADAS",
                                  e
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Fecha hasta:</Form.Label>
                            <Form.Control
                              type="date"
                              value={parametros.fecha_hasta}
                              name="fecha_hasta"
                              size="sm"
                              onChange={(e) =>
                                onChangeParametros(
                                  "TOTALES_COMISIONES_DETALLADAS",
                                  e
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="d-flex align-items-end">
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() =>
                              getEstadistica("TOTALES_COMISIONES_DETALLADAS")
                            }
                          >
                            <i class="fas fa-search"></i> Filtrar
                          </Button>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-right">
                          <small>
                            <strong>Total:</strong>{" "}
                            {numberFormat(
                              estadisticas.totales_comisiones_detalladas.reduce(
                                (acc, row) => acc + parseFloat(row.total_comision),
                                0
                              )
                            )}
                          </small>
                        </Col>
                      </Row>
                      <Table striped bordered hover size="xs">
                        <thead>
                          <tr>
                            <th>Comisionista</th>
                            <th>Total ($)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {estadisticas.totales_comisiones_detalladas.map(
                            (row) => (
                              <tr>
                                <td>{row.nombre_completo}</td>
                                <td>{numberFormat(row.total_comision)}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </>
        )}

        {"top_tratamientos_mas_vendidos" in estadisticas && (
          <>
            <Col xs={12} md={6} className="mb-4">
              <Card bg="default" className="shadow-sm">
                <Card.Header className="d-flex flex-row align-items-center flex-0">
                  <div className="d-block">
                    <h5 className="fw-normal mb-2">
                      Tratamientos más vendidos
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-2">
                  {estadisticas.top_tratamientos_mas_vendidos && (
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Tratamiento</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {estadisticas.top_tratamientos_mas_vendidos.map(
                          (tratamiento) => (
                            <tr>
                              <td>{tratamiento.nombre}</td>
                              <td>{tratamiento.cantidad}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Row>
      <Row className="justify-content-md-center">
        {"agendados_hoy_comisionista" in estadisticas && (
          <Col className="mb-4">
            <Card border="light" className="shadow-sm">
              <Card.Body>
                <Row className="d-block d-xl-flex align-items-center">
                  <Col
                    xl={5}
                    className="text-xl-left d-flex align-items-center justify-content-xl-left mb-3 mb-xl-0"
                  >
                    <h5>Agenda de hoy</h5>
                  </Col>

                  <Col xs={12}>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Hora</th>
                          <th>Cliente</th>
                          <th>Empleado</th>
                          <th>Confirmado</th>
                          <th>Teléfono</th>
                          <th>Nota</th>
                        </tr>
                      </thead>
                      <tbody>
                        {estadisticas.agendados_hoy_comisionista &&
                          estadisticas.agendados_hoy_comisionista.map(
                            (item, index) => {
                              const date = new Date(Date.parse(item.fecha));
                              return (
                                <tr>
                                  <td>{formatAMPM(date, true)}</td>
                                  <td>{item.cliente?.nombre_completo}</td>
                                  <td>
                                    {item.empleado
                                      ? item.empleado.nombre +
                                        " " +
                                        item.empleado.apellido_paterno
                                      : ""}
                                  </td>
                                  <td>
                                    {item.confirmado ? (
                                      <i
                                        class="fas fa-check-circle fa-2x"
                                        style={{ color: "green" }}
                                      ></i>
                                    ) : (
                                      <i
                                        class="fas fa-times-circle fa-2x"
                                        style={{ color: "red" }}
                                      ></i>
                                    )}
                                  </td>
                                  <td>{item.cliente?.telefono}</td>
                                  <td>{item.nota}</td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
        {"agendados_hoy" in estadisticas && (
          <Col className="mb-4">
            <Card border="light" className="shadow-sm">
              <Card.Body>
                <Row className="d-block d-xl-flex align-items-center">
                  <Col
                    xl={5}
                    className="text-xl-left d-flex align-items-center justify-content-xl-left mb-3 mb-xl-0"
                  >
                    <h5>Agenda de hoy</h5>
                  </Col>

                  <Col xs={12}>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Hora</th>
                          <th>Cliente</th>
                          <th>Empleado</th>
                          <th>Confirmado</th>
                          <th>Teléfono</th>
                          <th>Nota</th>
                        </tr>
                      </thead>
                      <tbody>
                        {estadisticas.agendados_hoy &&
                          estadisticas.agendados_hoy.map((item, index) => {
                            const date = new Date(Date.parse(item.fecha));
                            return (
                              <tr>
                                <td>{formatAMPM(date, true)}</td>
                                <td>{item.cliente}</td>
                                <td>{item.empleado}</td>
                                <td>
                                  {item.confirmado ? (
                                    <i
                                      class="fas fa-check-circle fa-2x"
                                      style={{ color: "green" }}
                                    ></i>
                                  ) : (
                                    <i
                                      class="fas fa-times-circle fa-2x"
                                      style={{ color: "red" }}
                                    ></i>
                                  )}
                                </td>
                                <td>{item.telefono}</td>
                                <td>{item.nota}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
        {/*<Col className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <Row className="d-block d-xl-flex align-items-center">
                <Col
                  xl={5}
                  className="text-xl-left d-flex align-items-center justify-content-xl-left mb-3 mb-xl-0"
                >
                  <h5>Ultimas productos vendidos</h5>
                </Col>

                <Col xs={12}>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>ID Venta</th>
                        <th>Cliente</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estadisticas.ultimasVentas &&
                        estadisticas.ultimasVentas.map((item, index) => {
                          return (
                            <tr>
                              <td>{item.id_venta}</td>
                              <td>{item.venta?.cliente?.nombre_completo}</td>
                              <td>
                                {item.producto
                                  ? item.producto?.nombre
                                  : item.tratamiento?.nombre}
                              </td>
                              <td>{item.cantidad}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        */}
      </Row>
    </>
  );
};
