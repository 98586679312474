import React, { useState, useEffect, Fragment, useRef, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  Table,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Alert,
  Form,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DefaultUser from "../../assets/img/team/default-user.png";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import {
  getProductosAction,
  agregarProductoAction,
  actualizarProductoAction,
  eliminarProductoAction,
  recalcularTotalesAction,
  limpiarTotalesAction,
  showModalTratamientoPreciosAction,
  comenzarFinanciarTratamientoAction,
  comenzarSeleccionarEmpleadoAction,
  showModalMetodoPagoAction,
  agregarVentaAction,
  actualizarProductosAction,
  actualizarDescuentoGeneralAction,
  actualizarVentaStateAction,
  limpiarProductosVentaAction,
} from "../../actions/ventaActions";
import { getTratamientosAction } from "../../actions/tratamientosActions";
import { getTratamientosPreciosAction } from "../../actions/tratamientosPreciosActions";
import { getEmpleadosAction } from "../../actions/empleadosActions";

import clienteAxios from "../../config/axios";
import ModalPreciosTratamiento from "./ModalPreciosTratamiento";
import ModalFinanciarTratamiento from "./ModalFinanciarTratamiento";
import ModalEmpleados from "./ModalEmpleados";
import ModalMetodoPago from "./ModalMetodoPago";
import AgregarPaciente from "../Pacientes/AgregarPaciente";
import {
  comenzarAgregarPacienteAction,
  getPacienteTratamientosFinanciadosAction,
  showModalPacienteTratamientosAction,
} from "../../actions/pacientesActions";
import LecturaMembresia from "../Membresias/LecturaMembresia";
import {
  setModalLecturaMembresiaAction,
  setNumeroMembresiaAction,
} from "../../actions/membresiasActions";
import ModalDescuento from "./ModalDescuento";
import ModalDescuentoIndividual from "./ModalDescuentoIndividual";
import TratamientosPaciente from "../Pacientes/TratamientosPaciente";
import { SocketContext } from "../../context/socket";

var crypto = require("crypto");

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const echo = useContext(SocketContext);

  const refTypeaheadCliente = useRef(null);

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    clienteSeleccionado: null,
    tratamientoSeleccionado: null,
    productoSeleccionado: null,
    queryBusqueda: "",
    showModalDescuento: false,
    showModalDescuentoIndividual: false,
    notaForm: "",
    ventaRealizada: false,
  });

  const [stateDescuento, setDescuento] = useState({
    tipo: "PORCENTAJE",
    porcentaje_valor: 0,
    monto_valor: 0,
    cupon_valor: "",
    descuento_general: false,
    descuento_por_cupon: false,
    descuento_comision: false,
  });

  const [defaultActiveKey, setKey] = useState("");
  const [primeraCategoria, setPrimeraCategoria] = useState("");

  var _cacheCliente = {};

  const {
    loadingProductosVenta,
    loadingAgregarVenta,
    productos,
    productosAgregados,
    totales,
    nota,
  } = useSelector((state) => state.venta);

  const { tratamientos } = useSelector((state) => state.tratamientos);

  const { clienteTratamientos, showModalClienteTratamientos } = useSelector(
    (state) => state.pacientes
  );

  const [adeudos, setAdeudos] = useState([]);

  useEffect(() => {
    checarAdeudos();
  }, [clienteTratamientos]);

  const checarAdeudos = () => {
    if (clienteTratamientos.length > 0) {
      const adeudos = clienteTratamientos.filter(
        (tratamiento) => tratamiento.financiado.saldo > 0
      );

      setAdeudos(adeudos);
    }
  };

  useEffect(() => {
    dispatch(getProductosAction());
    dispatch(getTratamientosAction());
    setAdeudos([]);
    limpiarDatos();

    obtenerClienteDesdeQuery();
  }, []);

  useEffect(() => {
    if (state.ventaRealizada === true) {
      limpiarDatos();
    }
  }, [state.ventaRealizada]);

  useEffect(() => {
    if (productosAgregados.length > 0) {
      dispatch(recalcularTotalesAction(productosAgregados));
    } else {
      dispatch(limpiarTotalesAction());
    }
  }, [productosAgregados]);

  const obtenerClienteDesdeQuery = () => {
    const queryParams = getQueryParams();
    const idClienteQuery = queryParams.get('id_cliente');

    if (idClienteQuery) {
      makeAndHandleRequestCliente(null, idClienteQuery)
        .then((resp) => {
          const cliente = resp.options[0];
          if (cliente) {
            setState({
              ...state,
              clienteSeleccionado: cliente,
            });

            getTratamientosFinanciados(cliente.id);
          }
        });
    }
  }

  const actualizarDescuentoGeneral = (state) => {
    setDescuento(state);
    dispatch(actualizarDescuentoGeneralAction(state));
  };

  const limpiarDatos = () => {
    state.clienteSeleccionado = null;

    setState({
      ...state,
      clienteSeleccionado: null,
      query: "",
      options: [],
      ventaRealizada: false,
    });

    if (refTypeaheadCliente) {
      refTypeaheadCliente.current.clear();
    }

    dispatch(limpiarProductosVentaAction());
  }

  const Productos = () => {
    const queryStr = state.queryBusqueda.toLowerCase();

    let productosRender = [];
    let i = 0;
    let tratamientosEncontrados = false;
    let tabs = [];

    if (tratamientos.length > 0) {
      const tratamientosHtml = tratamientos.map((tratamiento) => {
        const nombreTratamiento = tratamiento.nombre.toString();
        if (nombreTratamiento.toLowerCase().indexOf(queryStr) == -1) {
          return "";
        }

        tratamientosEncontrados = true;

        return (
          <Col key={`tratamiento-${tratamiento.id}`}>
            <Card className="text-center h-100">
              {tratamiento.imagen ? (
                <Card.Img
                  variant="top"
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    "/image/" +
                    tratamiento.imagen
                  }
                  style={{ height: "130px", objectFit: "cover" }}
                />
              ) : (
                <Card.Img
                  variant="top"
                  src="https://www.lwf.org/images/emptyimg.png"
                  style={{ height: "130px", objectFit: "cover" }}
                />
              )}
              <Card.Body style={{ padding: "10px" }}>
                <Card.Title style={{ fontSize: "15px" }}>
                  {tratamiento.nombre}
                </Card.Title>
              </Card.Body>
              <Card.Footer style={{ padding: "6px", fontWeight: "bold" }}>
                <Button
                  className="btn-sm"
                  variant="primary"
                  onClick={() => tratamientoPrecios(tratamiento)}
                >
                  Ver precios
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        );
      });

      if (tratamientosEncontrados === true) {
        const tratamientosTab = (
          <Tab eventKey="tratamientos" title="Tratamientos">
            <Row xs={2} sm={2} lg={3} xl={4} className="g-4">
              {tratamientosHtml}
            </Row>
          </Tab>
        );

        productosRender.push(tratamientosTab);
        tabs.push("tratamientos");
      }
    }

    productos.forEach((categoriaProducto) => {
      let categoriaProductosEncontrados = false;

      if (i == 0) {
        setPrimeraCategoria(categoriaProducto.nombre);
      }

      if (categoriaProducto.productos.length == 0) {
        return;
      }

      const productosHtml = categoriaProducto.productos.map((producto) => {
        if (producto.solo_servicio === true) {
          return "";
        }

        const nombreProducto = producto.nombre.toString();
        if (nombreProducto.toLowerCase().indexOf(queryStr) == -1) {
          return "";
        }

        categoriaProductosEncontrados = true;

        return (
          <Col key={`producto-${producto.id}`}>
            <Card
              className="text-center h-100"
              onClick={() => onClickProducto(producto)}
              style={{ cursor: "pointer" }}
            >
              {producto.imagen ? (
                <Card.Img
                  variant="top"
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    "/image/" +
                    producto.imagen
                  }
                  style={{ height: "130px", objectFit: "cover" }}
                />
              ) : (
                <Card.Img
                  variant="top"
                  src="https://www.lwf.org/images/emptyimg.png"
                  style={{ height: "130px", objectFit: "cover" }}
                />
              )}
              <Card.Body style={{ padding: "10px" }}>
                <Card.Title style={{ fontSize: "15px" }}>
                  {producto.nombre}
                </Card.Title>
              </Card.Body>
              <Card.Footer style={{ padding: "6px", fontWeight: "bold" }}>
                ${parseFloat(producto.precio).toFixed(2)}
              </Card.Footer>
            </Card>
          </Col>
        );
      });

      if (categoriaProductosEncontrados === true) {
        const categoriaTab = (
          <Tab
            eventKey={categoriaProducto.nombre}
            title={categoriaProducto.nombre}
          >
            <Row xs={2} sm={2} lg={3} xl={4} className="g-4">
              {productosHtml}
            </Row>
          </Tab>
        );

        productosRender.push(categoriaTab);
        tabs.push(categoriaProducto.nombre);
      }

      i++;
    });

    if (productosRender.length == 0) {
      return (
        <div className="text-center">
          <h3>No se encontraron productos</h3>
        </div>
      );
    }

    if (loadingProductosVenta === true) {
      return (
        <div class="spinner-border text-info mx-auto d-block" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      );
    }

    if (defaultActiveKey == "") {
      setKey(tabs[0]);
    }

    if (defaultActiveKey != "" && tabs.includes(defaultActiveKey) === false) {
      setKey(tabs[0]);
    }

    return (
      <>
        <Tabs
          defaultActiveKey={tabs[0]}
          activeKey={defaultActiveKey}
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(eventKey) => selectTab(eventKey)}
        >
          {productosRender}
        </Tabs>
      </>
    );
  };

  const selectTab = (key) => {
    setKey(key);
  };

  const showDescuentoGeneral = () => {
    setState({ ...state, showModalDescuento: true });
  };

  const onClickProducto = (producto) => {
    const checkExistProducto = productosAgregados.filter(
      (productoAgregado) =>
        productoAgregado.id == producto.id &&
        productoAgregado.tipo == "PRODUCTO"
    );

    if (checkExistProducto.length == 0) {
      const productoAgregado = {
        key: crypto.randomBytes(20).toString("hex"),
        id: producto.id,
        cantidad: 1,
        precio: producto.precio,
        nombre: producto.nombre,
        descuento_general: false,
        descuento_por_cupon: false,
        descuento_tipo: null,
        descuento_porcentaje: 0,
        descuento_monto: 0,
        descuento_comision: false,
        tipo: "PRODUCTO",
      };

      dispatch(agregarProductoAction(productoAgregado));
    } else {
      checkExistProducto[0].cantidad += 1;
      dispatch(actualizarProductoAction(checkExistProducto));
    }

    if (stateDescuento.descuento_general) {
      dispatch(actualizarDescuentoGeneralAction(stateDescuento));
    }
  };

  const onRemoveProducto = (producto) => {
    dispatch(eliminarProductoAction(producto));
  };

  const _handleInputChangeCliente = (query) => {
    state.query = query;
  };

  const makeAndHandleRequestCliente = (query = null, id = null) => {
    let config = { params: { query, info: false } };

    let url = "typeahead/clientes";
    if (id) {
      url = `typeahead/clientes/${id}`;
    }

    return clienteAxios.get(url, config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearchCliente = (query) => {
    if (_cacheCliente[query]) {
      setState({
        ...state,
        options: _cacheCliente[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestCliente(query).then((resp) => {
      _cacheCliente[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChangeCliente = (selectedOption) => {
    const value = selectedOption.length > 0 ? selectedOption[0] : null;

    setAdeudos([]);

    if (value) {
      getTratamientosFinanciados(value.id);
    }

    setState({
      ...state,
      clienteSeleccionado: value,
    });
  };

  const getTratamientosFinanciados = (idCliente) => {
    dispatch(getPacienteTratamientosFinanciadosAction(idCliente));
  };

  const cambiarCantidad = (producto, cantidad) => {
    producto.cantidad = parseInt(cantidad);

    if (cantidad == 0 && cantidad != "") {
      dispatch(eliminarProductoAction(producto));
    } else {
      dispatch(actualizarProductoAction(producto));
    }
  };

  const calcularImporte = (producto) => {
    const cantidad = isNaN(producto.cantidad) ? 0 : producto.cantidad;
    const importe = cantidad * parseFloat(producto.precio);
    return importe.toFixed(2);
  };

  const tratamientoPrecios = (tratamiento) => {
    dispatch(getTratamientosPreciosAction(tratamiento));
    dispatch(showModalTratamientoPreciosAction(true));
    setState({ ...state, tratamientoSeleccionado: tratamiento });
  };

  const financiar = (tratamiento) => {
    dispatch(comenzarFinanciarTratamientoAction(tratamiento));
  };

  const seleccionarEmpleado = (tratamiento) => {
    dispatch(getEmpleadosAction());
    dispatch(comenzarSeleccionarEmpleadoAction(tratamiento));
  };

  const seleccionarMetodoPago = () => {
    dispatch(showModalMetodoPagoAction(true));
  };

  const finalizarVenta = (metodoPago) => {
    const dataRequest = {
      productos: productosAgregados,
      metodoPago: metodoPago,
      id_cliente: state.clienteSeleccionado?.id,
      descuento_general: stateDescuento.descuento_general,
      descuento_por_cupon: stateDescuento.descuento_por_cupon,
      descuento_comision: stateDescuento.descuento_comision,
      nota: state.nota,
    };

    dispatch(agregarVentaAction(dataRequest, state, stateDescuento));
  };

  const queryBusquedaHandle = (e) => {
    setState({ ...state, queryBusqueda: e.target.value });
  };

  const onClickLeerMembresia = () => {
    dispatch(setNumeroMembresiaAction(null));
    dispatch(setModalLecturaMembresiaAction(true));
  };

  const aplicarDescuentoIndividual = (producto) => {
    setState({
      ...state,
      showModalDescuentoIndividual: true,
      productoSeleccionado: producto,
    });
  };

  const verAdeudos = () => {
    console.log(adeudos);
    dispatch(showModalPacienteTratamientosAction(true));
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Venta</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Row>
        <Col xs={12} md={12}>
          {adeudos.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading style={{ fontSize: "15px"}}>
                El cliente tiene adeudos pendientes. <Button variant="danger" size="xs" onClick={verAdeudos}>Ver adeudos</Button>
              </Alert.Heading>
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body>
              <Col xs={12} md={10} style={{ marginBottom: "30px" }}>
                <Form.Control
                  type="text"
                  placeholder="Ingresa nombre del producto/servicio a buscar"
                  onChange={queryBusquedaHandle}
                />
              </Col>
              <Productos />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={5}>
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <Row
                style={{ marginBottom: "10px" }}
                className="justify-content-md-end"
              >
                <Col xs={12} md={12}>
                  <Button
                    variant="info"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={() => dispatch(comenzarAgregarPacienteAction())}
                  >
                    <i class="fas fa-plus"></i> Nuevo cliente
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ float: "right", marginRight: "10px" }}
                    size="sm"
                    onClick={showDescuentoGeneral}
                  >
                    <i class="fas fa-percentage"></i> Descuento general
                  </Button>
                  {/*<Button variant="primary" style={{float:'right'}} size="sm" onClick={onClickLeerMembresia}>
                    <i class="fas fa-address-card"></i> Lector de membresia
                    </Button> */}
                </Col>
              </Row>
              <AsyncTypeahead
                {...state}
                ref={refTypeaheadCliente}
                clearButton
                id="async-clientes"
                labelKey="nombre_completo"
                minLength={2}
                onInputChange={_handleInputChangeCliente}
                onSearch={_handleSearchCliente}
                onChange={onChangeCliente}
                placeholder="Buscar cliente"
                renderMenuItemChildren={(option) => (
                  <div key={option.id}>
                    <img
                      alt={option.nombre_completo}
                      src={DefaultUser}
                      style={{
                        height: "24px",
                        marginRight: "10px",
                        width: "24px",
                      }}
                    />
                    <span>{option.nombre_completo}</span>
                  </div>
                )}
                selected={
                  state.clienteSeleccionado
                    ? [
                        {
                          id: state.clienteSeleccionado.id,
                          nombre_completo: state.clienteSeleccionado.nombre_completo,
                        },
                      ]
                    : undefined
                }
                useCache={false}
              />

              <hr />
              <Row
                className="table-wrapper table-responsive mb-4"
                style={{ maxHeight: "350px" }}
              >
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th className="border-bottom">Cantidad</th>
                      <th className="border-bottom">Producto</th>
                      <th className="border-bottom">Importe</th>
                      <th className="border-bottom">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productosAgregados.map((producto) => (
                      <>
                        <tr>
                          <td>
                            <input
                              class="form-control form-control-sm"
                              type="number"
                              value={producto.cantidad}
                              min="0"
                              onChange={(e) =>
                                cambiarCantidad(producto, e.target.value)
                              }
                            />
                          </td>
                          <td>{producto.nombre}</td>
                          <td>{calcularImporte(producto)}</td>
                          <td>
                            <Fragment>
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                overlay={<Tooltip>Comisionista</Tooltip>}
                              >
                                <button
                                  onClick={() => seleccionarEmpleado(producto)}
                                  className="btn btn-info btn-sm"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                >
                                  <i class="fas fa-user"></i>
                                </button>
                              </OverlayTrigger>
                              {producto.tipo == "TRATAMIENTO" && (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  overlay={<Tooltip>Financiar</Tooltip>}
                                >
                                  <button
                                    onClick={() => financiar(producto)}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginRight: "5px",
                                    }}
                                  >
                                    <i class="fas fa-coins"></i>
                                  </button>
                                </OverlayTrigger>
                              )}
                            </Fragment>
                            <Fragment>
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                overlay={<Tooltip>Descuento</Tooltip>}
                              >
                                <button
                                  onClick={() =>
                                    aplicarDescuentoIndividual(producto)
                                  }
                                  className="btn btn-success btn-sm"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                >
                                  <i class="fas fa-percentage"></i>
                                </button>
                              </OverlayTrigger>
                            </Fragment>
                            <button
                              onClick={() => onRemoveProducto(producto)}
                              className="btn btn-danger btn-sm p-1"
                              style={{
                                width: "30px",
                                height: "30px",
                                lineHeight: "0",
                              }}
                            >
                              {" "}
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                        </tr>
                        {(producto.id_empleado || producto.anticipo > 0) && (
                          <tr>
                            {producto.id_empleado && (
                              <td colSpan={2}>
                                <strong>Comisionista:</strong>{" "}
                                {producto.nombre_empleado}
                              </td>
                            )}
                            {producto.anticipo > 0 && (
                              <td colSpan={2}>
                                <strong>Anticipo:</strong> {producto.anticipo}
                              </td>
                            )}
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <hr />

              <Row>
                <Col>
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control
                    as="textarea"
                    className="rounded-0"
                    rows="1"
                    name="nota"
                    onChange={function (e) {
                      e.preventDefault();
                      dispatch(
                        actualizarVentaStateAction({
                          nota: e.target.value,
                        })
                      );
                    }}
                    value={nota}
                  />
                </Col>
              </Row>

              <hr />
              <ListGroup horizontal className="border-bottom">
                <ListGroup.Item className="bg-light w-50 rounded-0">
                  Subtotal
                </ListGroup.Item>
                <ListGroup.Item className="w-50 text-right">
                  ${totales.subtotal}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal className="border-bottom">
                <ListGroup.Item className="bg-light w-50 rounded-0">
                  Impuestos
                </ListGroup.Item>
                <ListGroup.Item className="w-50 text-right">
                  ${totales.impuestos}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal className="border-bottom">
                <ListGroup.Item className="bg-light w-50 rounded-0">
                  Descuentos
                </ListGroup.Item>
                <ListGroup.Item className="w-50 text-right">
                  ${totales.descuento}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal className="mb-4">
                <ListGroup.Item className="bg-light w-50 rounded-0">
                  Total a pagar
                </ListGroup.Item>
                <ListGroup.Item className="w-50 text-right">
                  ${totales.total}
                </ListGroup.Item>
              </ListGroup>
              {/*totales.total > 0 && metodoPago.recibido_total == 0 && (
                <Alert variant="warning">
                  <Alert.Heading
                    style={{ fontSize: "15px", textAlign: "center" }}
                  >
                    Selecciona o actualiza la información de pago
                  </Alert.Heading>
                </Alert>
              )
              <Button
                variant="primary"
                type="button"
                className="w-100 rounded-0"
                onClick={seleccionarMetodoPago}
                disabled={productosAgregados.length == 0}
              >
                <i class="fas fa-money-check-alt"></i> Método de pago
              </Button>*/}
              <Button
                variant="success"
                type="button"
                className="w-100 rounded-0"
                disabled={productosAgregados.length == 0 || loadingAgregarVenta}
                onClick={seleccionarMetodoPago}
              >
                <i class="fas fa-cash-register"></i> Realizar venta
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalPreciosTratamiento
        stateDescuento={stateDescuento}
        tratamiento={state.tratamientoSeleccionado}
      />
      <ModalFinanciarTratamiento />
      <ModalEmpleados />
      <ModalMetodoPago
        onSubmitForm={(metodoPago) => finalizarVenta(metodoPago)}
        idCliente={state.clienteSeleccionado?.id}
      />
      <AgregarPaciente />
      <LecturaMembresia />
      <ModalDescuento
        stateDescuento={stateDescuento}
        showModal={state.showModalDescuento}
        onHide={() => setState({ ...state, showModalDescuento: false })}
        onChange={(state) => actualizarDescuentoGeneral(state)}
      />

      <ModalDescuentoIndividual
        producto={state.productoSeleccionado}
        showModal={state.showModalDescuentoIndividual}
        onHide={() =>
          setState({ ...state, showModalDescuentoIndividual: false })
        }
        onChange={(producto) => {
          setState({ ...state, productoSeleccionado: null });
          dispatch(actualizarProductoAction(producto));
        }}
      />

      <TratamientosPaciente rows={adeudos}/>
    </>
  );
};
